import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { CourseFeeService } from '../../_services/course-fee.service';
import { CourseFee } from '../../_models/course-fee.modal';

@Component({
    selector: 'app-course-fee',
    imports: [CommonModule, SmallHeaderComponent, MatButtonModule],
    templateUrl: './course-fee.component.html',
    styleUrl: './course-fee.component.scss'
})
export class CourseFeeComponent implements OnInit {
  courseFee: CourseFee = { isActive: true };
  courseFeeService = inject(CourseFeeService);
  dialog = inject(MatDialog);

  imagePath = environment.imageUrl;

  breadCrumb = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Kurshonorar',
      link: '/kurshonorar',
    },
  ];

  ngOnInit(): void {
    this.courseFeeService.getAll().subscribe((res: CourseFee[]) => {
      const newestFee: CourseFee = res[res.length - 1];
      this.courseFee = newestFee;
    });
  }
}
