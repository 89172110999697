import { Component, Inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IAlbum, Lightbox, LightboxModule } from 'ngx-lightbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Event } from '../../_models/event.model';
import { environment } from '../../../environments/environment';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-event-dialog',
    imports: [
        CommonModule,
        LightboxModule,
        MatDialogModule,
        NgOptimizedImage,
        MatButtonModule,
    ],
    templateUrl: './event-dialog.component.html',
    styleUrl: './event-dialog.component.scss'
})
export class EventDialogComponent {
  album: IAlbum[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public event: Event,
    private lightbox: Lightbox
  ) {}

  ngOnInit(): void {
    if (this.event.imagePath) {
      this.album = [
        {
          src: this.replaceImageUrl(this.event.imagePath),
          caption: this.event.title,
          thumb: this.replaceImageUrl(this.event.imagePath),
          downloadUrl: this.replaceImageUrl(this.event.imagePath),
        },
      ];
    }
  }

  replaceImageUrl(path: string) {
    return environment.imageUrl + path;
  }

  open(index: number): void {
    this.lightbox.open(this.album, index);
  }
}
