import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallHeaderComponent } from '../../../_helpers/small-header/small-header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-philosophy',
    imports: [CommonModule, SmallHeaderComponent, MatIconModule, MatButtonModule],
    templateUrl: './philosophy.component.html',
    styleUrl: './philosophy.component.scss'
})
export class PhilosophyComponent {
  breadCrumb = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Über uns',
      link: '/ueber-uns',
    },
  ];
}
