import {
  afterNextRender,
  Component,
  HostListener,
  inject,
  input,
  InputSignal,
} from '@angular/core';
import { Cours, Event } from '../../../_models/nimbuscloud.model';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentDialogComponent } from '../../../_dialogs/appointment-dialog/appointment-dialog.component';
import { BookingDialogComponent } from '../../../_dialogs/booking-dialog/booking-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { CurrencyPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-courses-table',
    imports: [MatIconModule, MatButtonModule, CurrencyPipe],
    templateUrl: './courses-table.component.html',
    styleUrl: './courses-table.component.scss'
})
export class CoursesTableComponent {
  public courses: InputSignal<Cours[]> = input([] as Cours[]);
  public innerWidth = 999;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  dialog = inject(MatDialog);

  constructor() {
    afterNextRender(() => {
      this.onResize();
      this.innerWidth = window.innerWidth;
    });
  }

  getEventFuture(events: Event[]) {
    return events.filter((e) => e.isPastEvent === '0' && e.visible === '1');
  }

  openEvents(events: Event[]) {
    this.dialog.open(AppointmentDialogComponent, { data: events });
  }

  openBooking(cours: Cours) {
    this.dialog.open(BookingDialogComponent, {
      data: cours,
      width: '95vw',
      panelClass: 'full-screen-modal',
    });
  }
}
