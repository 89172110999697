<app-small-header title="Allgemeine Geschäftsbedingungen"></app-small-header>
<div id="content" class="content container" role="main">
  <h2>1. Buchung</h2>
  <p>
    Nach Eingang der Anmeldung ist die Buchung verbindlich und der Teilnehmende
    ist zur Zahlung des Kursbeitrags bis zur Kündigung verpflichtet. Änderungen
    der Termine und Angebote sind jederzeit möglich. Die Auswahl der
    Unterrichtsräume und der Lehrkräfte behält sich die Tanzschule vor.
  </p>

  <h2>2. Datenschutz und Änderungen</h2>
  <p>
    Alle umseitigen Angaben zur Person des Teilnehmenden (oder dem Kind) werden
    lediglich für die Mitgliederverwaltung verwendet. Sollten sich Name, Adresse
    oder Bankverbindung ändern, sind diese Änderungen der ADTV Tanzschule Seidl
    GmbH unverzüglich über die Dance Community (Kundencenter), E-Mail oder
    Telefon mitzuteilen.
  </p>

  <h2>3. Anmeldegebühr / Bearbeitungsgebühr</h2>
  <p>
    Bei der Erstanmeldung in der ADTV Tanzschule Seidl GmbH fallen keine
    gesonderten Gebühren an, wir übernehmen alle anfallenden Kosten. Sollte der
    Teilnehmende bereits Kunde der Tanzschule gewesen sein, berechnen wir bei
    Neustart eine Bearbeitungsgebühr von 20 Euro pro Person. Ein Wechsel in
    einen anderen Kurs oder Vertrag ist für aktive Kunden jederzeit kostenlos
    möglich.
  </p>

  <h2>4. Kursgebühr</h2>
  <p>
    Das Honorar wird jeweils zum 1. des Monats im Voraus fällig und mittels
    SEPA-Lastschriftverfahren eingezogen. Es wird auch dann fällig, wenn der
    Kunde nicht am Unterricht teilnimmt. Beitragsermäßigungen oder
    Rückvergütungen wegen Nichtteilnahme aus persönlichen Gründen sind
    ausgeschlossen (Urlaub, Krankheit etc.).
  </p>
  <p>
    Die monatliche Kursgebühr berechnet sich aus einem Jahreshonorar, welches in
    12 Raten aufgeteilt wird. Die Abbuchung findet dadurch auch in den
    unterrichtsfreien Zeiten statt. Bei nicht eingelösten Lastschriften entsteht
    zusätzlich eine Rücklastschriftgebühr in Höhe von 5 Euro.
  </p>
  <p>
    Preiserhöhungen in regelmäßigen Abständen bleiben vorbehalten und werden den
    Teilnehmenden oder Erziehungsberechtigten rechtzeitig im Voraus mitgeteilt.
    Bei Sparangeboten ändert sich nach Ablauf der Aktion der Vertrag automatisch
    zum Normaltarif und dessen Laufzeit beginnt.
  </p>

  <h2>5. SEPA Lastschriftmandat (Gläubiger ID: DE26ZZZ00000919934)</h2>
  <p>
    Mit Ihrer Unterschrift erteilen Sie der ADTV Tanzschule Seidl GmbH bis zur
    Kündigung des Vertrags die Bankeinzugsvollmacht des festgelegten Betrags.
    Ferner weisen Sie Ihr Kreditinstitut an, die von der ADTV Tanzschule Seidl
    GmbH gezogenen Lastschriften einzulösen.
  </p>

  <h2>6. Kündigung</h2>
  <p>
    Alle Verträge verlängern sich automatisch bis zum Eingang der Kündigung.<br />
    Die Kündigungsfrist beträgt einen Monat zum Monatsende. Sie kann unter
    Beachtung der Kündigungsfrist, frühestens aber zum Ablauf der
    Mindestlaufzeit erfolgen und muss schriftlich per E-Mail oder Post bei uns
    eingehen.
  </p>

  <h2>7. Mindestlaufzeit und Vertragspausen</h2>
  <p>
    Alle Verträge der ADTV Tanzschule Seidl GmbH haben eine Mindestlaufzeit von
    nur 3 Monaten und verlängern sich danach automatisch immer um einen weiteren
    Monat.
  </p>
  <p>
    Sollte bei Tanzzeitverträgen eine höhere Laufzeit abgeschlossen worden sein,
    verlängern sich auch diese Verträge nach Ablauf der vereinbarten Zeit,
    jeweils um einen weiteren Monat. Eine vorzeitige Kündigung ist
    ausgeschlossen.
  </p>
  <p>
    Eine Pausierung des Vertrags ist grundsätzlich nicht möglich. Die
    Entscheidung obliegt im Einzelfall der Tanzschule. Bei Einreichung eines
    ärztlichen Attests kann nach der 4. Krankheitswoche der Monatsbeitrag für
    die Dauer der Erkrankung max. über 3 Monate ruhen. Die Laufzeit verlängert
    sich dabei automatisch um die Ruhezeit.
  </p>

  <h2>8. Betriebsferien und Schulferien</h2>
  <p>
    Der Unterricht findet wöchentlich an den im Programm vorgesehenen Tagen
    statt, eine Mindestteilnehmerzahl von 3 wird vorausgesetzt, andernfalls
    behalten wir uns vor den Kurs abzusagen.
  </p>
  <p>
    Ausnahmen sind die gesetzlichen Feiertage und die Betriebsferien, die sich
    an die Schulferien des Landes Bayern anlehnen. Kinder- und Schülerkurse
    sowie auch unsere Tanzkreise entfallen in allen bayerischen Schulferien.
  </p>
  <p>Der Bankeinzug ist hiervon nicht beeinflusst.</p>

  <h2>9. Getränke (und Speisen)</h2>
  <p>
    Getränke werden in der Tanzschule zum käuflichen Erwerb angeboten. Da die
    Kursgebühren fair kalkuliert sind, bitten wir die Teilnehmenden höflich
    darum, unser Angebot von Getränken wahrzunehmen.
  </p>
  <p>
    Der Verzehr von mitgebrachten Getränken ist in den Räumlichkeiten und im
    Außenbereich der Tanzschule untersagt (eine Ausnahme von dieser Regelung
    betrifft Trinkflaschen).
  </p>

  <h2>10. Urheberrecht</h2>
  <p>
    Die Weitergabe von Schritten und Schrittkombis ist ausdrücklich untersagt.
    Die Inhalte der jeweiligen Kurse sind geistiges Eigentum der Tanzschule und
    unterliegen dem Urheberrecht. Das Filmen von Kurseinheiten ist ebenfalls
    verboten.
  </p>
  <p>
    Gelegentlich können bei Kursen oder Veranstaltungen der Tanzschule Foto- und
    Videoaufnahmen getätigt werden. Durch Akzeptieren der AGB ist der
    Teilnehmende ausdrücklich einverstanden, dass Foto-/Videoaufnahmen zu
    Werbezwecken genutzt und veröffentlicht werden dürfen. Sofern der
    Teilnehmende dies nicht wünscht, teilt er dies unverzüglich der Tanzschule
    Seidl mit. Andernfalls geht die Tanzschule davon aus, dass er der
    Veröffentlichung honorarfrei zustimmt und keinen Anspruch auf Film- oder
    Fotoechtheit erhebt.
  </p>

  <h2>11. Haftung</h2>
  <p>
    Die Tanzschule schließt jegliche Haftung für Schäden der Kunden aus. Dies
    gilt insbesondere für den Verlust von Wertgegenständen und Schäden aus
    Vertragsverletzungen. Die Teilnahme am Kurs findet auf eigene Gefahr statt.
    Für Verletzungen oder evtl. körperliche Schäden jeglicher Art übernimmt die
    ADTV Tanzschule Seidl GmbH keinerlei Haftung.
  </p>

  <h2>12. Sonstige Anmerkungen</h2>
  <ul>
    <li>
      Die ADTV Tanzschule Seidl GmbH gewährt dem Teilnehmenden für das
      vereinbarte Honorar die Nutzung der gebuchten Leistung. Im Sonderfall kann
      diese auch online durchgeführt werden. Der Bankeinzug wird hiervon nicht
      beeinflusst.
    </li>
    <li>
      Die ADTV Tanzschule Seidl erhebt Ihre Daten zwecks der
      Vertragsdurchführung, zur Erfüllung ihrer vertraglichen Pflichten sowie
      zur Direktwerbung (Newsletter). Der Teilnehmende erklärt sich bereit, dass
      die Kundendaten in unserer EDV gespeichert werden. Die Daten sind vor dem
      Zugriff nicht berechtigter Personen geschützt und werden nicht an Dritte
      weitergegeben (eine Ausnahme besteht nur für die Buchhaltung).
    </li>
  </ul>
</div>
