import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Landingpages } from './landing.helper';
import { BigHeaderComponent } from '../../_helpers/big-header/big-header.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'app-landingpages',
    imports: [BigHeaderComponent, NgOptimizedImage],
    templateUrl: './landingpages.component.html',
    styleUrl: './landingpages.component.scss'
})
export class LandingpagesComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  title?: string;
  subtitle?: string;
  id?: string;
  typeId?: string;
  currentRoute = this.router.url;

  landing = Landingpages;

  page?: any;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.currentRoute = this.router.url;
      this.title = params['title'];
      this.subtitle = params['subtitle'];
      this.id = params['id'];
      this.typeId = params['typeId'];
      this.page = this.landing.find((p) => p.alias === this.title);
      this.title = this.page.title;
      if (this.subtitle) {
        this.page = this.page.subitems.find(
          (p: any) => p.alias === this.subtitle
        );
        this.subtitle = this.page.title;
      }
    });
  }
}
