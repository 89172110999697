import { Component, afterNextRender } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IAlbum, Lightbox, LightboxModule } from 'ngx-lightbox';
import { SmallHeaderComponent } from '../../../_helpers/small-header/small-header.component';

@Component({
    selector: 'app-rooms',
    imports: [
        CommonModule,
        NgOptimizedImage,
        MatIconModule,
        MatButtonModule,
        LightboxModule,
        SmallHeaderComponent,
    ],
    templateUrl: './rooms.component.html',
    styleUrl: './rooms.component.scss'
})
export class RoomsComponent {
  breadCrumb = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Über uns',
      link: '/ueber-uns',
    },
  ];
  private path = './../../../../assets/raeume/';

  public album: IAlbum[] = [
    {
      src: this.path + 'IMG_6059.webp',
      caption: '',
      thumb: this.path + 'IMG_6059.webp',
      downloadUrl: this.path + 'IMG_6059.webp',
    },
    {
      src: this.path + 'IMG_6060.webp',
      caption: '',
      thumb: this.path + 'IMG_6060.webp',
      downloadUrl: this.path + 'IMG_6060.webp',
    },
    {
      src: this.path + 'IMG_6061.webp',
      caption: '',
      thumb: this.path + 'IMG_6061.webp',
      downloadUrl: this.path + 'IMG_6061.webp',
    },
    {
      src: this.path + 'IMG_6062.webp',
      caption: '',
      thumb: this.path + 'IMG_6062.webp',
      downloadUrl: this.path + 'IMG_6062.webp',
    },
    {
      src: this.path + 'IMG_6063.webp',
      caption: '',
      thumb: this.path + 'IMG_6063.webp',
      downloadUrl: this.path + 'IMG_6063.webp',
    },
    {
      src: this.path + 'IMG_6064.webp',
      caption: '',
      thumb: this.path + 'IMG_6064.webp',
      downloadUrl: this.path + 'IMG_6064.webp',
    },
    {
      src: this.path + 'IMG_6065.webp',
      caption: '',
      thumb: this.path + 'IMG_6065.webp',
      downloadUrl: this.path + 'IMG_6065.webp',
    },
    {
      src: this.path + 'IMG_6066.webp',
      caption: '',
      thumb: this.path + 'IMG_6066.webp',
      downloadUrl: this.path + 'IMG_6066.webp',
    },
    {
      src: this.path + 'IMG_6067.webp',
      caption: '',
      thumb: this.path + 'IMG_6067.webp',
      downloadUrl: this.path + 'IMG_6067.webp',
    },
    {
      src: this.path + 'IMG_6068.webp',
      caption: '',
      thumb: this.path + 'IMG_6068.webp',
      downloadUrl: this.path + 'IMG_6068.webp',
    },
    {
      src: this.path + 'IMG_6069.webp',
      caption: '',
      thumb: this.path + 'IMG_6069.webp',
      downloadUrl: this.path + 'IMG_6069.webp',
    },
    {
      src: this.path + 'IMG_6070.webp',
      caption: '',
      thumb: this.path + 'IMG_6070.webp',
      downloadUrl: this.path + 'IMG_6070.webp',
    },
  ];

  isGoogleMaps = false;

  constructor(private lightbox: Lightbox) {
    afterNextRender(() => {
      if (localStorage.getItem('isGoogleMaps') === 'true') {
        this.isGoogleMaps = true;
      } else {
        this.isGoogleMaps = false;
      }
    });
  }

  open(index: number): void {
    this.lightbox.open(this.album, index, {
      centerVertically: false,
      wrapAround: true,
    });
  }

  allowGoogleMap() {
    this.isGoogleMaps = true;
    localStorage.setItem('isGoogleMaps', 'true');
  }
}
