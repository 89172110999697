import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, NgForm } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MailService } from '../../_services/mail.service';
import { FormData } from '../../_models/mail.model';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-helloagain',
    imports: [
        CommonModule,
        SmallHeaderComponent,
        MatButtonModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatSnackBarModule,
    ],
    templateUrl: './helloagain.component.html',
    styleUrl: './helloagain.component.scss'
})
export class HelloagainComponent {
  public contact = {
    firstname: '',
    lastname: '',
    partnerName: '',
    mail: '',
    message: '',
  };

  constructor(private mail: MailService, private snackbar: MatSnackBar) {}

  sendForm(contactForm: NgForm) {
    if (this.contact?.message === undefined || this.contact?.message === null) {
      this.contact.message = '';
    }
    const formData: FormData = {
      mail: environment.mailTo,
      subject: 'Anmeldung Hello-Again Sonntagsbrunch',
      text: `Name: ${this.contact.firstname} ${this.contact.lastname}\n Tanzpartner/in: ${this.contact.partnerName}\nE-Mail: ${this.contact.mail}\n\n\nNachricht:\n${this.contact.message}`,
      html: `<table><tr><td>Name: </td><td>${
        this.contact.firstname + ' ' + this.contact.lastname
      }</td></tr><tr><td>Tanzpartner/in: </td><td>${
        this.contact.partnerName
      }</td></tr></tr><tr><td>E-Mail: </td><td>${
        this.contact.mail
      }</td></tr></table><br><strong>Nachricht:</strong><br>${this.contact.message.replace(
        /\n/g,
        '<br />'
      )}`,
    };

    this.mail.sendForm(formData).subscribe({
      next: (value) => {
        this.snackbar.open('Erfolgreich versendet!', '', {
          duration: 3000,
          panelClass: 'bg-success',
        });
        contactForm.resetForm();
      },
      error: (error) => {
        console.log(error);
        this.snackbar.open('Fehler beim Senden!', '', {
          duration: 3000,
          panelClass: 'bg-danger',
        });
      },
      complete: () => console.log('Complete'),
    });
  }
}
