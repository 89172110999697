import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SmallHeaderComponent } from '../../../_helpers/small-header/small-header.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, NgForm } from '@angular/forms';
import { MailService } from '../../../_services/mail.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { FormData } from '../../../_models/mail.model';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-education',
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        SmallHeaderComponent,
        NgOptimizedImage,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatSnackBarModule,
        MatSelectModule,
    ],
    templateUrl: './education.component.html',
    styleUrl: './education.component.scss'
})
export class EducationComponent {
  public contact = {
    name: '',
    mail: '',
    tel: '',
    subject: '',
    message: '',
  };

  breadCrumb = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Über uns',
      link: '/ueber-uns',
    },
  ];

  constructor(private mail: MailService, private snackbar: MatSnackBar) {}

  sendForm(contactForm: NgForm) {
    const formData: FormData = {
      mail: environment.mailTo,
      subject:
        'Kontaktanfrage (' +
        this.contact.subject +
        ') von: ' +
        this.contact.name,
      text: `Name: ${this.contact.name}\nE-Mail: ${this.contact.mail}\nTelefon: ${this.contact.tel}\Betreff: ${this.contact.subject}\n\nNachricht:\n${this.contact.message}`,
      html: `<table><tr><td>Name: </td><td>${
        this.contact.name
      }</td></tr><tr><td>E-Mail: </td><td>${
        this.contact.mail
      }</td></tr><tr><td>Telefon: </td><td>${
        this.contact.tel
      }</td></tr><tr><td>Betreff: </td><td>${
        this.contact.subject
      }</td></tr></table><br><strong>Nachricht:</strong><br>${this.contact.message.replace(
        /\n/g,
        '<br />'
      )}`,
    };

    this.mail.sendForm(formData).subscribe({
      next: (value) => {
        this.snackbar.open('Erfolgreich versendet!', '', {
          duration: 3000,
          panelClass: 'bg-success',
        });
        contactForm.resetForm();
      },
      error: (error) => {
        console.log(error);
        this.snackbar.open('Fehler beim Senden!', '', {
          duration: 3000,
          panelClass: 'bg-danger',
        });
      },
      complete: () => console.log('Complete'),
    });
  }
}
