import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SmallHeaderComponent } from '../../../_helpers/small-header/small-header.component';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../../../environments/environment';
import { Team } from '../../../_models/team.model';
import { TeamService } from '../../../_services/team.service';

@Component({
    selector: 'app-team',
    imports: [
        CommonModule,
        SmallHeaderComponent,
        NgOptimizedImage,
        MatButtonModule,
    ],
    templateUrl: './team.component.html',
    styleUrl: './team.component.scss'
})
export class TeamComponent implements OnInit {
  team: Team[] = [];

  imagePath = environment.imageUrl;

  breadCrumb = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Über uns',
      link: '/ueber-uns',
    },
  ];

  constructor(private teamService: TeamService) {}

  ngOnInit(): void {
    this.teamService.getAll(0, 999999, '').subscribe((res) => {
      this.team = res.rows;
    });
  }
}
