import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallHeaderComponent } from '../../../_helpers/small-header/small-header.component';

@Component({
    selector: 'app-imprint',
    imports: [CommonModule, SmallHeaderComponent],
    templateUrl: './imprint.component.html',
    styleUrl: './imprint.component.scss'
})
export class ImprintComponent {}
