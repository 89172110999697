import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallHeaderComponent } from '../../../_helpers/small-header/small-header.component';

@Component({
    selector: 'app-privacy',
    imports: [CommonModule, SmallHeaderComponent],
    templateUrl: './privacy.component.html',
    styleUrl: './privacy.component.scss'
})
export class PrivacyComponent {}
