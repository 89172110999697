import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-small-header',
    imports: [CommonModule],
    templateUrl: './small-header.component.html',
    styleUrl: './small-header.component.scss'
})
export class SmallHeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() breadcrumb: any[] = [];
  @Input() snow: boolean = false;

  constructor(private titleService: Title) {
    this.title = this.capitalizeFirstLetter(this.title);
    titleService.setTitle(
      this.title + ' | ADTV Tanzschule Seidl Amberg | Tanzen Amberg'
    );
  }

  ngOnInit(): void {
    this.title = this.capitalizeFirstLetter(this.title);
    this.titleService.setTitle(
      this.title + ' | ADTV Tanzschule Seidl Amberg | Tanzen Amberg'
    );
  }

  capitalizeFirstLetter(inputString: string) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }
}
