import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NimbuscloudService } from '../../../_services/nimbuscloud.service';
import { Level, Type } from '../../../_models/nimbuscloud.model';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-courses-box',
    imports: [CommonModule, NgOptimizedImage],
    templateUrl: './courses-box.component.html',
    styleUrl: './courses-box.component.scss'
})
export class CoursesBoxComponent implements OnInit {
  public types: Type[] = [];
  public levels: Level[] = [];
  public type?: Type;
  @Input() typeId: string = '';
  @Input() levelId: string = '';

  nimbuscloudUrl = environment.nimbuscloudUrl;

  constructor(private nimbus: NimbuscloudService) {}

  ngOnInit(): void {
    this.nimbus.getTypes().subscribe({
      next: (types) => {
        if (types.statuscode === 200) {
          this.types = this.imageChange(types.content.types);
          // this.types = types.content.types;
          if (this.typeId && this.typeId !== '') {
            const type = this.types.find((t) => +t.id === +this.typeId);
            this.type = type;
            if (type) this.levels = type.levels;
          }
        }
      },
      error: (err) => console.error(err),
    });
  }

  imageChange(types: Type[]) {
    return types.map((type) => {
      if (!type.previewImage) return type;
      const splitted = type.previewImage.url.split('/');
      const splitted2 = splitted[splitted.length - 1].split('.');
      const imageName = splitted2[0];
      const imageExt = splitted2[1];
      type.newImage =
        this.nimbuscloudUrl + 'image/' + imageName + '/' + imageExt;
      return type;
    });
  }

  escapeSlash(urlParam: string) {
    // remove all leading and trailing spaces
    urlParam = urlParam.trim();
    // html encode the url param to avoid XSS attacks
    urlParam = encodeURI(urlParam);
    // replace ( and ) with %28 and %29
    urlParam = urlParam.trim().replace(/[(]+/gi, '%28');
    urlParam = urlParam.trim().replace(/[)]+/gi, '%29');
    // replace / with %2f
    urlParam = urlParam.trim().replace(/[\/]+/gi, '%2f');
    // replace space with %20
    urlParam = urlParam.trim().replace(/[ ]+/gi, '%20');
    return urlParam;
  }
}
