import { Component, HostListener, afterNextRender } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  Event,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'app-main',
    imports: [
        CommonModule,
        RouterOutlet,
        RouterLink,
        MatIconModule,
        MatButtonModule,
        FontAwesomeModule,
    ],
    templateUrl: './main.component.html',
    styleUrl: './main.component.scss'
})
export class MainComponent {
  isScrolled = false;
  currentUrl = '';
  anio = new Date().getFullYear();
  fabFacebook = faFacebook;
  fabInstagram = faInstagram;
  fabTiktok = faTiktok;

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    let verticalOffset = 0;
    if (typeof window !== 'undefined') {
      verticalOffset = window.scrollY;
    } else if (typeof document !== 'undefined') {
      verticalOffset =
        document.documentElement.scrollTop || document.body.scrollTop || 0;
    }

    if (verticalOffset > 188) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  constructor(private router: Router) {
    afterNextRender(() => {
      this.onScroll();
    });
  }

  ngOnInit(): void {
    this.onScroll();
    this.currentUrl = this.router.url;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }
}
