import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SmallHeaderComponent } from '../../../../_helpers/small-header/small-header.component';
import { Team } from '../../../../_models/team.model';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { TeamService } from '../../../../_services/team.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-single-team',
    imports: [CommonModule, SmallHeaderComponent, NgOptimizedImage],
    templateUrl: './single-team.component.html',
    styleUrl: './single-team.component.scss'
})
export class SingleTeamComponent implements OnInit {
  public id?: number;
  public member?: Team;
  public job: string[] = [];
  public imagePath = environment.imageUrl;

  breadCrumb = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Über uns',
      link: '/ueber-uns',
    },
    {
      title: 'Team',
      link: '/ueber-uns/team',
    },
  ];

  constructor(
    route: ActivatedRoute,
    private teamService: TeamService,
    private metaTagService: Meta,
    private title: Title
  ) {
    route.paramMap.subscribe((params) => {
      let link = params.get('name');
      if (link && link !== '') {
        let linkParts = link.split('-');
        this.id = parseInt(linkParts[linkParts.length - 1]);
      }
    });
    title.setTitle('Team | ADTV Tanzschule Seidl - Tanzen in Amberg');
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Herzlich Willkommen auf den Seiten der ADTV Tanzschule Seidl! Standard Latein (Welttanzprogramm), Hochzeitskurse, Privatstunde, Discofox, Salsa, Hip Hop, Ballett, Zumba, Kinder-Teenie-Tanzen, Linedance uvm',
    });
  }

  loadData() {
    if (!this.id || !+this.id) {
      return;
    }
    this.teamService.getById(+this.id, '').subscribe((res) => {
      this.member = res;
      if (!this.member.job) {
        this.job = [];
      } else {
        this.job = this.member.job.split('\n') || [];
      }
      this.metaDescription(this.member);
    });
  }

  metaDescription(member: Team) {
    if (!member) return;
    let desc =
      member.name +
      ' - ' +
      this.job.join(', ') +
      ' - ' +
      'ADTV Tanzschule Seidl Amberg';
    this.metaTagService.updateTag({
      name: 'description',
      content: desc,
    });
    this.title.setTitle(
      member.name + ' | ADTV Tanzschule Seidl - Tanzen in Amberg'
    );
  }
}
