import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';

@Component({
    selector: 'app-about-us',
    imports: [CommonModule, SmallHeaderComponent, NgOptimizedImage],
    templateUrl: './about-us.component.html',
    styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {}
